.trigger-logo-zapier
{
    display: flex;
    border: 1px solid #e0e4e6;
    border-radius: 30%;
    height: 65px;
}
.Content-logo-box{
    opacity: .2;
}
.title-text
{
    font-weight: bold;
}
.p-text
{
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 1px;
}
.question-box
{
    background-color: #000;
    padding: 0 6px;
    border-radius: 22px;
    color: #fff;
    cursor: pointer;
}
.zap-line
{
    width: 2px;
    height: 20px;
    background: #000;
    margin: 0 auto;
    background: #999;
}
.arrow-zap
{
    margin: -5px;
    color: #999;
}
.content-color
{
    background: #fff;
    padding: 20px;
    border-right: 1px solid #9cacb4;
    border-bottom: 1px solid #9cacb4;
    border-left: 1px solid #9cacb4;
    border-radius: 0 0 4px 4px;
}
.content-box{
    background: #fafafa;
    padding: 30px;
    border-radius: 10px
}
.logo-app
{
    border: 1px solid #ffbd59;
    margin-right: 20px;
    border-radius: 10px;
}
.ones-text
{
    font-size: 19px;
    font-weight: 600;
}
.two-text
{
    font-weight: 300;
}
.logo-box-one
{
    width: 33.33%!important;
    display: flex;
    align-items: center;
    padding: 15px 15px;
    border-radius: 10px;
}
.logo-box-one:hover
{
    background: #fff;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
}
.img-set
{
    display: inline-flex;
    margin-right: 12px
}
.logo-box{
    padding: 20px 0;
    margin-top: 20px;
}
.work-logo
{
    width: 100%!important;
    display: flex!important;
}
.zap-height
{
    height: calc(100vh - 138px);
    overflow-y: auto;
}