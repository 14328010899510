:root {
  --red: #eb004c;
  /* --user_orange:#fa7811 */
  --logo_orange: rgb(179 95 23);
  --white: #fff;
  --border: #d2d3d4;
  --user_orange: #315c62;
  --bg_color: #f9f8f4;
  --font_color: 'black';
  --dot_color:#42442f;
}

body {

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slide {
  animation: slideup 4s ease-in both;
  /* animation-name: slideup;
  animation-duration: 4s;
  animation-timing-function: ease-in;
  animation-fill-mode: both */
}

@keyframes slideup {

  0%,
  10% {
    transform: translateY(100%);
    opacity: 0;
  }

  20%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* .error{
  border: 3px solid var(--red) !important;
} */

.user-input .form-control:focus {
  border: 2px solid var(--user_orange) !important;
  box-shadow: none !important;
}

.target {
  animation: 0.3s slideright;
}

@keyframes slideright {
  from {
    margin-left: -15%;
    opacity: 0;
  }

  to {
    opacity: 1;
    margin-right: -10%;
  }
}

.target_none {
  animation: 0.4s slideleft;
}

@keyframes slideleft {
  from {
    margin-right: -15%;
    opacity: 1;
    display: block;
  }

  to {
    opacity: 0;
    margin-left: -10%;
    display: none;
  }
}

.bg {
  background-color: var(--user_orange);
}

.user_form  h2 {
  color: var(--font_color) !important;
}
.user_form  span {
  color: var(--font_color);
}
.user_form  h3 {
  color: var(--font_color) !important;
}
.user_form  input {
  color: var(--font_color) !important;
}
.user_form  p {
  color: var(--font_color) !important;
}
.user_form  a {
  color: var(--font_color) !important;
}

.user_form input {
  font-size: 18px !important;
}

.user_form input::placeholder {
  font-size: 18px !important;
  color: '';
}

.user_form input:focus::placeholder {
  opacity: 1 !important;
  color: gray !important;
}