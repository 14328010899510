/* :root{
    --dot:#fa7811;
    --white:white;
    --black:black
} */
.css-14sza3e-MuiStepLabel-root {
    flex-direction: column !important;
    align-items: flex-start !important;
}

.css-1vyamtt-MuiStepLabel-labelContainer {
    margin: 6px 0px !important;
    text-transform: uppercase !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    height: 40px !important;
    width: 40px !important;
    /* height: 12px !important;
    width: 20px !important; */
}

.css-117w1su-MuiStepIcon-text {
    display: none;
}

.css-8t49rw-MuiStepConnector-line {
    border-left-width: 2px !important;
    min-height: 35px !important;
}

.css-1pe7n21-MuiStepConnector-root {
    margin-left: 9px !important;
}

.css-h2cmlr {
    text-align: center;
    margin-top: 8px;
}

.css-a5nipc {
    padding-right: 0px !important;
}

/* dots */
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: #212529 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: var(--logo_orange) !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
    color: var(--logo_orange) !important;
    font-weight: 600 !important;
}

.css-r12ba4 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    padding: 8px 0px;
}

/* step text on server */

.css-2fdkz6.Mui-active {
    color: rgb(179 95 23) !important;
}

/* lines */
.css-vgb7rt {
    transform: translateX(50%);
}

.css-52tf1x {
    margin-left: 0px !important;
}

/* lines on server */
.css-15oeqyl{
    top: 20px !important;
}

/* dots on server */
.css-4ff9q7 {
    height: 40px !important;
    width: 40px !important;
    /* height: 12px !important;
    width: 20px !important; */
}

.css-4ff9q7.Mui-completed {
    color: #212529 !important;
}

.css-4ff9q7.Mui-active {
    color: var(--logo_orange) !important;
}

.css-10psl7k {
    display: none !important;
}

/* horizontal stepper */

/* on server ::= .css-1187icl */
.css-10mg1vw-MuiStepper-root ,.css-1187icl{
    width: 65%;
    margin-left: auto;
    justify-content: center !important;
    align-items: center !important;
    margin-right: auto;
}

@media only screen and (min-width: 1200px) {
    .css-1kf6ifa-MuiContainer-root {
        max-width: 86% !important;
    }

    /* on server stepper width */
    .css-djcxqq{
        max-width: 86% !important; 
    }
}

.css-rp0fdj-MuiContainer-root {
    /* width */
    max-width: 86% !important;
}

.css-zpcwqm-MuiStepConnector-root {
    /* line between labels */
    top: 18px !important;
}