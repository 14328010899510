.token-text {
    width: 390px!important;
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
}
.google-oauth-btn {
    background-size: 0px!important; 
    background-repeat: none!important;
    background-position: 0px!important;
    text-indent: 0px!important;
    border: 1px solid #ba9137!important;
    padding:var(--vz-btn-padding-y) var(--vz-btn-padding-x) !important;
    border-radius: var(--vz-btn-border-radius)!important;
    background-color: #ba9137!important;
    font-size: 16px!important;
}
.que-text {
    width: 356px!important;
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
}
.sque-text {
    width: 230px!important;
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
}
.sv-text {
    width: 195px!important;
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
}
.swal-ok
{
    background-color: #315662!important;
}
.swal-cancle
{
    background-color: #ed725a!important;
    color: white!important;
}
.todo-height
{
     height: calc(100vh - 202px)!important;
}
@media (max-width: 425px) {
    .todo-height
{
     height: calc(100vh - 227px)!important;
}
}