.form-todo-content {
    height: calc(100vh - 189px) !important;
}
@media (max-width: 1040px){
    .form-todo-content {
        height: calc(100vh - 190px) !important;
    }
}

@media (max-width: 425px) {
    .form-todo-content {
        height: calc(100vh - 216px) !important;
        overflow-y: auto !important;
    }
}

@media (max-width: 320px) {
    .form-todo-content {
        height: calc(100vh - 216px) !important;
        overflow-y: auto !important;
    }
}

.form-grid-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    margin-bottom: 15px;
}

.form-grid-item {
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center; */
}