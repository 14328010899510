/* 
:root{
    --orange:#fa7811;
}




/* -------------------------search select---------------------------------- */

.user-select .css-13cymwt-control {
    padding: 8px 0px !important;
}
/* .user-select .w_93{
    width:93% !important;
}
.user-select .w_49{
    width: 49% !important;
} */
/* .user-select .css-b62m3t-container{
    border-radius: 4px !important;
    border: 1px solid #d2d3d4;
}
.user-select .css-1nmdiq5-menu{
    border: 3px solid var(--orange) !important;
    z-index: 999 !important;
}
.user-select .css-t3ipsp-control{
    border: 2px solid var(--orange) !important;
    min-height: 32px !important;
    padding: 8px  !important;
    box-shadow: none !important;
}
.user-select .css-1jqq78o-placeholder{
    text-align: start !important;
}
.user-select .css-1g0ciz2-singleValue{
    text-align: start !important;
}
.user-select .css-13cymwt-control{
border-style: none !important;
border-color: none !important;
} */

/* 
:root{
    --orange:#fa7811;
} */
.user-select .css-t3ipsp-control{
    border: 1px solid var(--user_orange) !important;
    min-height: 51px !important;
    padding: 8px  !important;
    box-shadow: none !important;
}
.user-select .css-1nmdiq5-menu{
    border: 2px solid var(--user_orange) !important;
    /* z-index: 999 !important; */
}
.user-select .css-13cymwt-control{
    padding: 0px!important;
    border: none!important;
    min-height: 51px!important;
    
}

.user-select .css-1u9des2-indicatorSeparator{
    margin: 0px!important;
    display: none!important;
}
/* .user-select .css-tj5bde-Svg{
    display: none!important;
} */
.user-select .css-13cymwt-control:hover{
    border-color: none!important;
    border: none!important;
    box-shadow: none!important;
}
.user-select .css-1sjk6ym-control:hover{
    border-color: none!important;
    border: none!important;
    box-shadow: none!important;
}

.user-select .css-1fdsijx-ValueContainer{
    padding: 2px 12px!important;
}
.user-select .css-16xfy0z-control{
    border-color: transparent!important;
    background-color: transparent!important;
    border-style: none!important;
   
}
.user-select .css-894a34-indicatorSeparator
{
    background-color: transparent!important;
}
.user-select .select-disable
{
    background-color: hsl(0, 0%, 95%)!important;
    /* border-color: hsl(0, 0%, 90%); */
}

/* selected value */

.css-ofnnlg-singleValue{
    font-size: 18px !important;
}