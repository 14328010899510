.coverage-details__item {
    align-items: center;
    color: #4a4f53 !important;
    display: flex;
    text-align: left;
    font-size: 15px;
}
.coverage-details__item__title__text{
    font-weight: 500;
}
@media screen and (min-width: 375px)
{
    .coverage-details__item__title__abbv {
        display: none;
    }
}
/* .coverage-details__item__title:after {
    content: " ";
    white-space: pre;
} */
.coverage-details__item__leaders:after {
    white-space: nowrap;
    width: 0;
    content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
}
.coverage-details__item__value:before {
    content: " ";
    white-space: pre;
}
.coverage-details__item__value {
    flex: 0 0 auto;
    font-weight: 700;
    padding: 0.5rem 0;
}
.coverage-details__item__leaders {
    flex: 1 1 auto;
    overflow-x: hidden;
    padding: 0.5rem 0;
    width: 0;
}
.value-tag {
    height: fit-content;
    background-color: #d0e7fe;
    border-radius: 4px;
    color: #0068c8;
    display: inline-block;
    font-size: .875rem;
    font-weight: 700;
    padding: 0.25rem 0.5rem;
}

.btn_quote {
    align-items: flex-end;
    display: flex !important;
    color: var(--user_orange) !important;
    font-weight: 600 !important;
    height: 60px !important;
    padding: 0 32px !important;
    border: 2px solid var(--user_orange);
    /* background-color: var(--orange) !important; */
    font-size: 1rem !important;
    border-radius: 2.1875rem !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    margin-top: 2rem !important;
    position: relative;
}
.user_form .btn_quote_selected{
    background-color: var(--user_orange) !important;
    color: var(--white) !important;
}
.user_form .btn_quote_selected p{
    color: var(--white) !important;
}

.btn_quote:hover{
    border: 2px solid var(--user_orange) ;
}

.btn_quote p {
    margin-right: 10px;
}
.scroll-images {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }