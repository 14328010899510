/* :root {
  --border: #d2d3d4;
  --orange: #fa7811;
  --white: #fff;
} */

.label {
  border: 1px solid var(--border);
  background-color: var(--white);
  font-size: 18px;
  color: var(--font_color);
}

input[type="radio"]:checked {
  background: var(--user_orange) ;
  border: 5px solid var(--white) !important;
  padding: 6px !important;
}

input[type="radio"] {
  padding: 10px;
}
/* input[type="radio"]:checked {
  padding: 11px;
  background-color: var(--user_orange);
  border: none;
  box-shadow: none;
} */

.bg {
  background-color: var(--user_orange);
}
