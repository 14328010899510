.google-sheet-logo
{
    height: 100px;
    width: 100px;
    object-fit: cover;
    
}
.google-logo-div
{
    display: flex;
    justify-content: center;
    align-items: center;
}
