:root{
    --darkBlue:#315662
}
.back-drop{
position: fixed;
display: block;
opacity: 0.6;
z-index: 999999; 
left: 0;
top: 0;
height: 100%;
width: 100%;
background-color: rgb(39 37 37 / 21%);

}
.loader-center
{
    display: block;
    position: absolute;
    z-index: 2; 
    top:50%;
    left: 50%;
    height: 40px;
    width: 40px;
    color:  var(--darkBlue);
}
.loader-top
{
    display: block;
    position: absolute;
    z-index: 2; 
    top:10%;
    left: 50%;
    height: 40px;
    width: 40px;
    color:  var(--darkBlue);
}
.fs-50
{
    font-size: 50px;
}