/* visited page  */
.css-gujj89 {
    background-image: none !important;
    background-color: var(--dot_color) !important; 
    /* green logo color */
}

/* active page */
.css-1dl46hm {
    background-image: none !important;
    background-color: var(--logo_orange) !important;
}

/* stepper bg color */
.css-1l30vw-MuiStack-root{
    background-color: var(--bg_color);
}

/* loader line color --------------3rd-4th is live class */
.css-u8eh2j-MuiStepConnector-root.Mui-completed .MuiStepConnector-line,
.css-u8eh2j-MuiStepConnector-root.Mui-active .MuiStepConnector-line,
.css-8vtk1h.Mui-active .MuiStepConnector-line,.css-8vtk1h.Mui-completed .MuiStepConnector-line {
    background-image: linear-gradient(95deg, rgb(179 95 23) 0%, rgb(179 95 23) 50%, rgb(36 73 77) 100%) !important;
}


/*  on server lines */
.css-8vtk1h .MuiStepConnector-line {
    height: 3px !important;
}



/* icon-size */
.css-gujj89 svg,
.css-1dl46hm svg,
.css-1mr67r1 svg {
    height: 25px !important;
    width: 29px !important;
}