.sidebar-background1
{
    /* background: var(--vz-vertical-menu-bg-dark);
     */
     background-color: #315662;
     border-right: 1px solid #fcf7f2;
    /* border-right: 1px solid var(--vz-vertical-menu-bg-dark); */
}
.navbar-nav .nav-link
{
    /* color: var(--vz-vertical-menu-item-color-dark)!important;
     */
     /* color: #7ebfb9ab; */
     color: #d4f1eeab;
    cursor: pointer!important;
}
.navbar-nav .nav-link:hover{
    color:white!important;
}
.navbar-nav .nav-item:hover{
    /* color:white!important; */
}
.navbar-menu .navbar-nav .nav-sm .nav-item{
    /* color: #7ebfb9ab; */
    color: #d4f1eeab;
}
.navbar-menu .navbar-nav .nav-sm .nav-link:hover:before {
    background-color:white!important;
}
.navbar-nav .nav-item .menu-dropdown .nav .nav-item:hover .nav-link-dash,.navbar-nav .nav-item .menu-dropdown .nav .nav-item:hover .nav-link{
    color: white !important;
}
.selected
{
    color: white!important;
}
.not-selected
{
    /* color: #7ebfb9ab!important;
     */
    color: #d4f1eeab!important;
}
.logo-image-color
{
    filter: brightness(100)!important;
}
.select-arrow::after
{
    transform: rotate(90deg)!important;
}
.submenu-up{
    -webkit-transform:translateY(-200%);
    -moz-transform:translateY(-200%);
    transform:translateY(-100%);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.submenu-down{
    -webkit-transform:translateY(0px);
    -moz-transform:translateY(0px);
    transform:translateY(0px);
     -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.sidebar-open
{
    display: block;
    
}
.sidebar-close
{
    display: none;
}
@media screen and (max-width: 425) {
   .offcanvas-backdrop.show
    {
        display: none!important;
    }
}

@media (min-width: 768px) and (max-width: 3000px) 
{
    .offcanvas-backdrop.show
    {
        display: none!important;
    }
}
.nav-link-dash
{
    padding: 0.40rem 0rem !important;
    color: inherit;
}
.nav-dash:hover{
    cursor: pointer;
}
.m-250
{
    margin-left: 250px;
}

