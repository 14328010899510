.pagination {
    padding: 0.75rem  0.375rem;
    font-size: 0.8125rem;
    color: white;
    border:1px solid #e9ebec;
  
}
.listjs-pagination {
    margin-bottom: 0;
    justify-content: flex-end;
    gap: 8px;
}.page-item
{
    display: block!important;
    padding: 0.375rem 0.75rem!important;
    color: var(--vz-link-color)!important;
    background-color: var(--vz-card-bg-custom)!important;
    border: 1px solid var(--vz-border-color)!important;
    border-radius: 0.25rem!important;
  
}
.page-item-active
{
    color: white!important;
    background-color: #ba9137!important;
    border-radius: 0.25rem!important;
    border: 1px solid #ba9137!important;
    height: fit-content;

}
@media (max-width:395px) {
    .page-item {
        padding: 0.375rem 0.6rem!important;
    }
}
@media (max-width:320px) {
    .page-item {
        padding: 0.375rem 0.4rem!important;
    }
}