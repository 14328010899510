/* :root {
    --orange: #fa7811;
    --white: #fff
} */

.save-btn {
    background-color: var(--user_orange) !important;
    color: var(--white) !important;
    font-weight: 600 !important;
}

.btn_aerrow_icon {
    /* margin-left: 10px; */
    height: 2em;
    width: 1.5em;
    position: absolute;
    top: 28%;
    right: 10% !important;
    transition: .4s ease;

}

.btn_preview_save {
    align-items: flex-end;
    background-color: var(--user_orange);
    display: flex !important;
    color: var(--white) !important;
    font-weight: 600 !important;
    height: 60px !important;
    padding: 0 32px !important;
    font-size: 1rem !important;
    border-radius: 2.1875rem !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    margin-top: 2rem !important;
    position: relative;
}

.btn_save {
    align-items: flex-end;
    background-color: var(--user_orange) !important;
    display: flex !important;
    color: var(--white) !important;
    font-weight: 600 !important;
    height: 60px !important;
    padding: 0 32px !important;
    font-size: 1rem !important;
    border-radius: 2.1875rem !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    margin-top: 2rem !important;
    position: relative;
}

.btn_save p {
    margin-right: 17px;
    color: var(--white) !important;
}

.btn_save:hover .btn_aerrow_icon {
    right: 8% !important;
}

.btn_save .not-allowed:hover {
    cursor: not-allowed !important;
    /* background-color: var(--btn_hover_bg) !important; */
}