.custom-radio input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.custom-radio .radio-wrap {
    background-color: #fff;
    border: 1px solid #e8e9e9;
    border-radius: 4px;
    cursor: pointer;
    padding: 1rem;
    transition: background-color .1s cubic-bezier(.4, 0, .2, 1), border-color .1s cubic-bezier(.4, 0, .2, 1), box-shadow .1s cubic-bezier(.4, 0, .2, 1), color .1s cubic-bezier(.4, 0, .2, 1);
}

.custom-radio.is-selected .radio-control {
    background-color: #0a0c0e;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60%;
    border: none;
}

.custom-radio .radio-control {
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    flex-shrink: 0;
    height: 24px;
    -webkit-user-select: none;
    user-select: none;
    width: 24px;
    transition: background-color .1s cubic-bezier(.4, 0, .2, 1), border-color .1s cubic-bezier(.4, 0, .2, 1), color .1s cubic-bezier(.4, 0, .2, 1);
}

.custom-radio.is-selected .radio-wrap {
    border: 3px solid #0a0c0e;
}

.custom-coverage-card {
    background-color: #fff;
    border: 1px solid #e8e9e9;
    border-radius: 4px;
    cursor: pointer;
    padding: 1rem;
    transition: background-color .1s cubic-bezier(.4, 0, .2, 1), border-color .1s cubic-bezier(.4, 0, .2, 1), box-shadow .1s cubic-bezier(.4, 0, .2, 1), color .1s cubic-bezier(.4, 0, .2, 1);
}

.secondary-button {
    align-items: center;
    background: none;
    display: inline-flex;
    filter: none;
    font-family: Roboto, Helvetica Neue, Arial, Verdana, sans-serif;
    font-weight: 500;
    justify-content: center;
    transition: all .25s ease;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    font-size: 1rem;
    line-height: 1.5;
    background-color: #0000;
    border: 0 solid #0000;
    outline: none;
    transition: box-shadow .1s cubic-bezier(.4, 0, .2, 1);
}

.secondary-button--gray {
    box-shadow: 0 0 0 0 #0000, inset 0 0 0 2px #4a4f53;
    color: #4a4f53;
}

.secondary-button--pill {
    border-radius: 2.1875rem !important;
}

.secondary-button--md {
    border-radius: 0.375rem;
    height: 56px;
    padding: 0 32px;
    font-size: 1rem;
    line-height: 1.5;
}

.accordion-item:first-of-type .accordion-button {
    border-radius: 10px;
}

.accordion-button {
    width: fit-content;
    display: block;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    color: #4a4f53;
    outline: none;
    transition: box-shadow .1s cubic-bezier(.4, 0, .2, 1);
    padding: 20px;
    border-radius: 10px;
}

.accordion-button:hover {
    background-color: #e8e9e9;
    color: var(--user_orange) !important;
    text-decoration: underline !important;
}

.accordion-item {
    padding: 20px;
    width: 80%;
    text-align: justify;
}

.btn-get-quote {
    border-radius: 24px;
    background-color: white;
    color: var(--user_orange);
    padding: 14px 24px;
    border: 2px solid var(--user_orange);
}

.coverage-container .policy-coverage,
.coverage-container .vehicle-coverage {
    width: calc(50% - 15px);
}

.tab-content .coverage-container {
    display: flex;
    justify-content: space-between;
}

.tab-col-subheaders {
    background-color: #e8e9e9;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
}

.coverage-container .row-wrap {
    display: flex;
    justify-content: space-between;
}

.coverage-container .coverage-key {
    margin-right: 1rem;
    overflow: hidden;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (min-width: 1200px) {
    .carrier-rectangular-logo {
        max-width: 250px;
    }
}

@media (min-width: 992px) {
    .carrier-rectangular-logo {
        max-width: 200px;
    }
}

@media (min-width: 576px) {
    .carrier-rectangular-logo {
        max-width: 170px;
    }
}

.carrier-rectangular-logo {
    /* margin-right: 1rem; */
    max-height: 50px;
    max-width: 150px;
    width: auto;
}