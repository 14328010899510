.bg {
  background-color: var(--user_orange);
  color: var(--white);
}
.user-custom input[type="checkbox"]:checked {
  /* background: #fa7811 !important; */
  border: 3px solid #fff !important;
  padding: 6px !important;
}
.user-custom input[type="checkbox"] {
  padding: 10px;
  
}
.user-custom input[type="checkbox"]:checked {
  /* padding: 11px; */
  background-color: var(--white);
  border: none;
  box-shadow: none;
}
.user-custom .form-check-input:checked[type=checkbox] {
/* background-image: url('../../../assets/check.png') !important; */
}
.user-checkbox .is-invalid{
  background-image: none !important;
}